<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검년월(예정, 완료) -->
          <c-datepicker
            label="LBL0002200"
            name="month"
            type="month"
            default="today"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="MIM_CHECK_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검부서 -->
          <c-dept
            :isFirstValue="false"
            type="search"
            label="LBL0002201"
            name="checkDeptCd"
            v-model="searchParam.checkDeptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <!-- 안전점검일지 목록 -->
    <c-table
      ref="table"
      title="LBL0002202"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="onItemClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "safety-check",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "LBLPLANT",
            align: "center",
            sortable: false,
          },
          {
            name: "safetyCheckName",
            field: "safetyCheckName",
            style: 'width:200px',
            // 점검명
            label: "점검명",
            align: "left",
            type: "link",
            sortable: false,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            // 점검부서
            label: "LBL0002201",
            align: "center",
            sortable: false,
          },
          {
            name: "checkStatusName",
            field: "checkStatusName",
            // 진행상태
            label: "LBLSTEPPROGRESS",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0002204',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            // 점검월
            label: "LBL0002205",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        month: '',
        checkStatusCd: null,
        checkDeptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.safetycheck.plan.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick();
    },
    linkClick(row) {
      this.popupOptions.title = 'LBL0002206'; // 안전점검일지 상세
      this.popupOptions.target = () => import(`${"./safetyCheckDetail.vue"}`);
      this.popupOptions.param = {
        safetyCheckId: row ? row.safetyCheckId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>